import ApartmentIcon from '@mui/icons-material/Apartment';
import ArticleIcon from '@mui/icons-material/Article';
import PeopleIcon from '@mui/icons-material/People';


const menuItemsAdmin = [
  {
    heading: '',
    items: [
      {
        name: 'Utenti',
        link: '/management/users',
        icon: PeopleIcon
      },
      {
        name: 'Logs',
        link: '/status/coming-soon',
        icon: ArticleIcon
      }
    ]
  }
];

export default menuItemsAdmin;
