import axios from "axios";
import StorageService from "./storage";

const instance = axios.create({
  baseURL: "https://api.bbpass.com/api",
  //baseURL: "http://localhost:3020/api",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = StorageService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/auth/refreshtoken", {
            token: StorageService.getLocalRefreshToken(),
          });

          const { accesstoken } = rs.data.data;
          StorageService.updateLocalAccessToken(accesstoken);

          return instance(originalConfig);
        } catch (_error) {
          if (_error.response.status === 404 || _error.response.status === 403) {
            StorageService.removeUser();
            window.location.href = '/';
          }
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
