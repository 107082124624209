import api from "./api";
import TokenService from "./storage";


const login = (username, pass) => {
  return api
    .post("/auth/signin", {
      email: username,
      password: pass
    })
    .then((response) => {
      if (response.data.data.accesstoken) {
        TokenService.setUser(response.data.data);
      }

      return response.data;
    });
};

const logout = () => {
    const refreshtoken = TokenService.getLocalRefreshToken();
    return api
    .post("/auth/signout", {
        token: refreshtoken
    });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  login,
  logout,
  getCurrentUser,
};

export default AuthService;