import ApartmentIcon from '@mui/icons-material/Apartment';
import ArticleIcon from '@mui/icons-material/Article';
import PeopleIcon from '@mui/icons-material/People';


const menuItemsOwner = [
  {
    heading: '',
    items: [
      {
        name: 'Strutture',
        link: '/management/buildings',
        icon: ApartmentIcon
      },
      {
        name: 'Logs',
        link: '/status/coming-soon',
        icon: ArticleIcon
      }
    ]
  }
];

export default menuItemsOwner;
