import { AESEncrypt, AESDecrypt }  from '../utils/helper';

const getLocalRefreshToken = () => {
  if (localStorage.getItem("user") == null) return null;
  const user = AESDecrypt(localStorage.getItem("user"));
  return user?.refreshtoken;
};

const getLocalAccessToken = () => {
  if (localStorage.getItem("user") == null) return null;
  const user = AESDecrypt(localStorage.getItem("user"));
  return user?.accesstoken;
};

const updateLocalAccessToken = (token) => {
  let user = AESDecrypt(localStorage.getItem("user"));
  user.accesstoken = token;
  localStorage.setItem("user", AESEncrypt(user));
};

const updateData = (nome, surname, email) => {
  let user = AESDecrypt(localStorage.getItem("user"));
  user.nome = nome;
  user.surname = surname;
  user.email = email;
  localStorage.setItem("user", AESEncrypt(user));
};

const getUser = () => {
  if (localStorage.getItem("user") == null) {
    return null;
  }
  return AESDecrypt(localStorage.getItem("user"));
};

const setUser = (user) => {
  localStorage.setItem("user", AESEncrypt(user));
};

const removeUser = () => {
  localStorage.removeItem("user");
};

const StorageService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getUser,
  setUser,
  removeUser,
  updateData
};

export default StorageService;
