import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

const Signin = Loader(lazy(() => import('src/content/signin')));

const Buildings = Loader(lazy(() => import('src/content/buildings')));
const BuildingCreate = Loader(lazy(() => import('src/content/buildings/create')));
const BuildingEdit = Loader(lazy(() => import('src/content/buildings/edit')));
const BuildingDetail = Loader(lazy(() => import('src/content/buildings/details')));
const Rooms = Loader(lazy(() => import('src/content/rooms')));
const RoomCreate = Loader(lazy(() => import('src/content/rooms/create')));
const RoomEdit = Loader(lazy(() => import('src/content/rooms/edit')));
const Codes = Loader(lazy(() => import('src/content/codes')));
const CodeCreate = Loader(lazy(() => import('src/content/codes/create')));
const CodeEdit = Loader(lazy(() => import('src/content/codes/edit')));
const HousedportalEdit = Loader(lazy(() => import('src/content/housedportal/edit')));
const IstatportalEdit = Loader(lazy(() => import('src/content/istatportal/edit')));
const Extracodes = Loader(lazy(() => import('src/content/extracodes')));
const Calendar = Loader(lazy(() => import('src/content/calendar')));
const ExtracodeEdit = Loader(lazy(() => import('src/content/extracodes/edit')));
const CheckinEdit = Loader(lazy(() => import('src/content/checkin/edit')));
const Checkin = Loader(lazy(() => import('src/content/checkin')));
const CheckinOnline = Loader(lazy(() => import('src/content/checkin/online')));
const CheckinSuccess = Loader(lazy(() => import('src/content/checkin/success')));
const CheckinError = Loader(lazy(() => import('src/content/checkin/error')));
const Logs = Loader(lazy(() => import('src/content/logs')));
const Users = Loader(lazy(() => import('src/content/users')));
const UserCreate = Loader(lazy(() => import('src/content/users/create')));
const UserEditAdmin = Loader(lazy(() => import('src/content/users/edit')));
const UserSettings = Loader(lazy(() => import('src/content/settings')));
const ChangePassword = Loader(lazy(() => import('src/content/settings/changepassword')));
const EditProfile = Loader(lazy(() => import('src/content/settings/edit')));

// Status

const Status404 = Loader(lazy(() => import('src/content/status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/status/Maintenance')));


const routes = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/*',
        element: <Signin />
      },
      {
        path: 'signin',
        element: (
          <Navigate
            to="/"
            replace
          />
        )
      },
      {
        path: 'check-in',
        children: [
          {
            path: '*',
            element: (
              <Navigate
                to="online"
                replace
              />
            )
          },
          {
            path: 'online',
            element: <CheckinOnline />
          },
          {
            path: 'success',
            element: <CheckinSuccess />
          },
          {
            path: 'error',
            element: <CheckinError />
          }
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '*',
            element: (
              <Navigate
                to="404"
                replace
              />
            )
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          },
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
    ]
  },
  {
    path: 'management',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: 'buildings',
        element: <Buildings />
      },
      {
        path: 'createbuilding',
        element: <BuildingCreate />
      },
      {
        path: 'editbuilding',
        element: <BuildingEdit />
      },
      {
        path: 'detailbuilding',
        element: <BuildingDetail />
      },
      {
        path: 'rooms',
        element: <Rooms />
      },
      {
        path: 'createroom',
        element: <RoomCreate />
      },
      {
        path: 'editroom',
        element: <RoomEdit />
      },
      {
        path: 'codes',
        element: <Codes />
      },
      {
        path: 'createcode',
        element: <CodeCreate />
      },
      {
        path: 'editcode',
        element: <CodeEdit />
      },
      {
        path: 'edithousedportal',
        element: <HousedportalEdit />
      },
      {
        path: 'editistatportal',
        element: <IstatportalEdit />
      },
      {
        path: 'checkin',
        element: <Checkin />
      },
      {
        path: 'editcheckin',
        element: <CheckinEdit />
      },
      {
        path: 'extracodes',
        element: <Extracodes />
      },
      {
        path: 'calendar',
        element: <Calendar />
      },
      {
        path: 'editextracode',
        element: <ExtracodeEdit />
      },
      {
        path: 'logs',
        element: <Logs />
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'createuser',
        element: <UserCreate />
      },
      {
        path: 'edituser',
        element: <UserEditAdmin />
      },
      {
        path: 'profile',
        children: [
          {
            path: '*',
            element: (
              <Navigate
                to="settings"
                replace
              />
            )
          },
          {
            path: 'settings',
            element: <UserSettings />
          },
          {
            path: 'changepassword',
            element: <ChangePassword />
          },
          {
            path: 'editprofile',
            element: <EditProfile />
          }
        ]
      }
    ]
  }
];


export default routes;
