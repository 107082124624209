var CryptoJS = require("crypto-js");

export function AESEncrypt(pureText) {    
    const privateKey='YNVnXHtWWgcs7g2w2TvY5ap8rGxUrjcbQndYVwVWRKEAuL4Gapsw2TvcbQndg2YVwV7g2w2TvcbQndg2YVwVWR';    
    var ciphertext = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(pureText), privateKey).toString());    
    return ciphertext;
}

export function AESDecrypt(encryptedText) {  
    const privateKey='YNVnXHtWWgcs7g2w2TvY5ap8rGxUrjcbQndYVwVWRKEAuL4Gapsw2TvcbQndg2YVwV7g2w2TvcbQndg2YVwVWR';    
    var bytes  = CryptoJS.AES.decrypt(decodeURIComponent(encryptedText), privateKey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));    
    return decryptedData;
}